import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// @emotion/css
import { css } from "@emotion/css";

// @mui/material
import { Box, Typography, Button, IconButton, useTheme } from "@mui/material";

// @mui/icons-material
import MenuIcon from "@mui/icons-material/Menu";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

// sito components
import SitoImage from "sito-image";
import { scrollTo } from "some-javascript-utils/browser";

// components
import NavigationDrawer from "../NavigationDrawer/NavigationDrawer";

// contexts
import { useMode } from "../../context/ModeProvider";
import { useLanguage } from "../../context/LanguageProvider";

// images
/* import logoLight from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png"; */

const Navbar = () => {
  const theme = useTheme();
  const location = useLocation();

  const { languageState } = useLanguage();
  const { modeState, setModeState } = useMode();

  const [showDrawer, setShowDrawer] = useState(false);

  const [cPathname, setCPathname] = useState("/");

  useEffect(() => {
    const { hash } = location;
    setCPathname(hash.length ? hash : "/");
    if (hash.length) {
      const htmlDOM = document.getElementById(hash.substring(1));
      if (htmlDOM !== null) scrollTo(htmlDOM.offsetTop);
    } else scrollTo(0);
  }, [location]);

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      sx={{
        gap: "10%",
        display: "flex",
        padding: "5px 25px",
        background: "",
      }}
    >
      <Box display="flex" alignItems="center" gap="30px" flex={1}>
        <Link
          to="/"
          className={css({
            color: theme.palette.secondary.main,
            textDecoration: "none",
          })}
        >
          {/* <SitoImage
            src={modeState.mode === "light" ? logoLight : logoDark}
            alt="logo"
            sx={{
              width: "110px",
              height: "25px",
            }}
          /> */}
          <Box
            sx={{ width: "110px", height: "25px", background: "blue" }}
          ></Box>
        </Link>
      </Box>

      <Box
        sx={{
          gap: "30px",
          display: { xs: "none", md: "flex" },
          alignItems: "center",
        }}
      >
        {languageState.texts.Navbar.Links.map((item) => (
          <Link
            key={item.to}
            to={item.to}
            className={css({
              textDecoration: "none",
            })}
          >
            <Typography
              sx={{
                color: `${
                  theme.palette[
                    item.to === cPathname ? "alterSecondary" : "disabled"
                  ].main
                } !important`,
                fontWeight: item.to === cPathname ? "bold" : "400",
              }}
            >
              {item.label}
            </Typography>
          </Link>
        ))}
        {/* <Button
          size="small"
          color="primary"
          variant="contained"
          sx={{ borderRadius: "25px", width: "130px" }}
        >
          {languageState.texts.Buttons.Login}
        </Button> */}
        <IconButton
          color="inherit"
          onClick={() => setModeState({ type: "toggle" })}
        >
          {modeState.mode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>
      </Box>
      <Button
        onClick={() => setShowDrawer(true)}
        sx={{
          display: { xs: "inherit", md: "none" },
          width: "40px",
          height: "40px",
          minHeight: 0,
          minWidth: 0,
          padding: 0,
          borderRadius: "100%",
        }}
      >
        <MenuIcon />
      </Button>
      <NavigationDrawer
        open={showDrawer}
        handleClose={() => setShowDrawer(false)}
      />
    </Box>
  );
};

export default Navbar;
