// sito components
import ErrorBoundary from "sito-mui-error-component";

// components
import ToTop from "../../components/ToTop/ToTop";

// @mui/material
import { Box } from "@mui/material";

// sections
import Hero from "./Sections/Hero";
import ContactUs from "./Sections/ContactUs";
import Services from "./Sections/Services";
import AboutUs from "./Sections/AboutUs";
import Reviews from "./Sections/Reviews";

const Home = () => {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <ToTop></ToTop>
      <ErrorBoundary>
        <Hero />
      </ErrorBoundary>
      <ErrorBoundary>
        <AboutUs />
      </ErrorBoundary>
      <ErrorBoundary>
        <Services />
      </ErrorBoundary>
      <ErrorBoundary>
        <Reviews />
      </ErrorBoundary>
      <ErrorBoundary>
        <ContactUs />
      </ErrorBoundary>
    </Box>
  );
};

export default Home;
