// @mui/material
import { useTheme, Box, Typography } from "@mui/material";

// components
import ActionCard from "../../../components/ActionCard/ActionCard";
import InViewComponent from "../../../components/InViewComponent/InViewComponent";

// contexts
import { useLanguage } from "../../../context/LanguageProvider";

// utils
import { parseI } from "../../../utils/parser";

// images
import services from "../../../assets/images/services";

const Services = () => {
  const theme = useTheme();

  const { languageState } = useLanguage();

  return (
    <Box
      id="services"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: theme.palette.background.paper,
        padding: {
          md: "0 10rem 8rem 10rem",
          sm: "0 5rem 5rem 5rem",
          xs: "30px",
        },
      }}
    >
      <InViewComponent delay="0.9s">
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          {languageState.texts.Home.Services.Title}
        </Typography>
      </InViewComponent>
      <Box
        sx={{
          gap: "20px",
          display: "flex",
          marginTop: "20px",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        {languageState.texts.Home.Services.Services.map(
          ({ title, content }, i) => (
            <InViewComponent delay={`${parseI(1.1, i)}s`}>
              <ActionCard
                title={title}
                body={content}
                sx={{ flex: 1 }}
                src={services[i]}
              />
            </InViewComponent>
          )
        )}
      </Box>
    </Box>
  );
};

export default Services;
