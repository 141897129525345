import React from "react";
import ReactDOM from "react-dom/client";

// @fontsource
import "@fontsource/roboto";
import "@fontsource/poppins";

// styles
import "./index.css";

// app
import App from "./App";

// contexts
import { ModeProvider } from "./context/ModeProvider";
import { LanguageProvider } from "./context/LanguageProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LanguageProvider>
    <ModeProvider>
      <App />
    </ModeProvider>
  </LanguageProvider>
);
