import { useState } from "react";

// sito components
import SitoImage from "sito-image";
import { useNotification } from "sito-mui-notification";

// @mui/material
import { useTheme, Box, Button, TextField, Typography } from "@mui/material";

// components
import InViewComponent from "../../../components/InViewComponent/InViewComponent";

// contexts
import { useLanguage } from "../../../context/LanguageProvider";

// images
import contact from "../../../assets/images/contact.jpg";

const ContactUs = () => {
  const theme = useTheme();

  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const showNotification = (ntype, message) =>
    setNotificationState({
      type: "set",
      ntype,
      message,
    });

  const [ok, setOk] = useState(true);

  const [name, setName] = useState("");
  const [nameHelperText, setNameHelperText] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneHelperText, setPhoneHelperText] = useState("");

  const [email, setEmail] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");

  const [message, setMessage] = useState("");
  const [messageHelperText, setMessageHelperText] = useState("");

  const validate = () => setOk(true);

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id, value } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "email": {
          if (!value.length)
            return setEmailHelperText(languageState.texts.Errors.EmailRequired);
          else
            return setEmailHelperText(languageState.texts.Errors.InvalidEmail);
        }
        case "phone": {
          if (!value.length)
            return setPhoneHelperText(languageState.texts.Errors.PhoneRequired);
          else
            return setPhoneHelperText(languageState.texts.Errors.InvalidPhone);
        }
        case "message":
          return setMessageHelperText(
            languageState.texts.Errors.MessageRequired
          );
        default: // name
          return setNameHelperText(languageState.texts.Errors.NameRequired);
      }
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
  };

  const handlePhone = (e) => {
    const { value } = e.target;
    if (/[a-zA-Z]/g.test(value) && value.length > 0)
      setPhoneHelperText(languageState.texts.Errors.InvalidPhone);
    setPhone(value);
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    if (
      (value.indexOf("@") < 0 ||
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) &&
      value.length > 0
    )
      setEmailHelperText(languageState.texts.Errors.InvalidEmail);
    setEmail(value);
  };

  return (
    <Box
      id="contact-us"
      sx={{
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
        background: theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          zIndex: 2,
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: { lg: "50%", md: "75%", xs: "100%" },
          alignItems: { md: "flex-start", xs: "center" },
          padding: { md: "0 5rem", sm: "0 5rem", xs: "30px" },
        }}
        component="form"
        onSubmit={sendMessage}
      >
        {/* Name */}
        <InViewComponent delay="0.3s">
          <Typography variant="h5">
            {languageState.texts.Home.ContactUs.Title}
          </Typography>
        </InViewComponent>
        <InViewComponent delay="0.5s" sx={{ width: "100%" }}>
          <TextField
            required
            id="name"
            value={name}
            onInput={validate}
            variant="outlined"
            sx={{ width: "100%" }}
            onInvalid={invalidate}
            helperText={nameHelperText}
            onChange={(e) => setName(e.target.value)}
            label={languageState.texts.Home.ContactUs.Name.Label}
            color={nameHelperText.length > 0 ? "error" : "primary"}
            placeholder={languageState.texts.Home.ContactUs.Name.Placeholder}
          />
        </InViewComponent>
        {/* Phone */}
        <InViewComponent delay="0.7s" sx={{ width: "100%" }}>
          <TextField
            required
            id="phone"
            type="phone"
            value={phone}
            onInput={validate}
            variant="outlined"
            onChange={handlePhone}
            sx={{ width: "100%" }}
            onInvalid={invalidate}
            helperText={phoneHelperText}
            label={languageState.texts.Home.ContactUs.Phone.Label}
            color={phoneHelperText.length > 0 ? "error" : "primary"}
            placeholder={languageState.texts.Home.ContactUs.Phone.Placeholder}
          />
        </InViewComponent>
        {/* Email */}
        <InViewComponent delay="0.9s" sx={{ width: "100%" }}>
          <TextField
            required
            id="email"
            type="email"
            value={email}
            onInput={validate}
            variant="outlined"
            onChange={handleEmail}
            sx={{ width: "100%" }}
            onInvalid={invalidate}
            helperText={emailHelperText}
            label={languageState.texts.Home.ContactUs.Email.Label}
            color={emailHelperText.length > 0 ? "error" : "primary"}
            placeholder={languageState.texts.Home.ContactUs.Email.Placeholder}
          />
        </InViewComponent>
        {/* Message */}
        <InViewComponent delay="1.1s" sx={{ width: "100%" }}>
          <TextField
            rows={6}
            multiline
            id="message"
            value={message}
            sx={{ width: "100%" }}
            helperText={messageHelperText}
            onChange={(e) => setMessage(e.target.value)}
            color={emailHelperText.length > 0 ? "error" : "primary"}
            label={languageState.texts.Home.ContactUs.Message.Label}
            placeholder={languageState.texts.Home.ContactUs.Message.Placeholder}
          />
        </InViewComponent>
        <InViewComponent delay="1.3s">
          <Button
            type="submit"
            variant="contained"
            color="alterSecondary"
            sx={{ width: "120px" }}
          >
            {languageState.texts.Buttons.Send}
          </Button>
        </InViewComponent>
      </Box>
      <Box
        sx={{
          height: "100vh",
          position: "relative",
          width: { lg: "50%", md: "75%", xs: 0, overflow: "hidden" },
        }}
      >
        <Box
          sx={{
            zIndex: 1,
            width: "100%",
            height: "100%",
            position: "absolute",
            background: `${theme.palette.secondary.dark}77`,
          }}
        ></Box>
        <SitoImage
          src={contact}
          alt="contact-truck"
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
    </Box>
  );
};

export default ContactUs;
